import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const SeoConfig = {
  title: `Psychothérapie enfants - ados - adultes`,
  description: `Psychothérapie et Coaching à Troyes - Accompagnement psychologique pour Bébé, Enfant, Adolescent, Adulte.
  Je reçois en individuel, en couple ou dans des entretiens familiaux.`,
  author: `Cédric LE HIR`,
  siteUrl: "https://psytroyes.fr",
  image: "https://psytroyes.fr/logo.png",
  social: [
    {
      name: "twitter",
      url: "https://twitter.com/cedlhr",
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/cedriclehirpsy/",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/clhpsy/",
    },
  ],

}

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  dateModified}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url:url,
      name: title,
      alternateName: SeoConfig.title,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'MedicalBusiness',
      "@id": url,
      "name": "Cédric LE HIR | Psychothérapie et coaching enfants - ados - adultes",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "90 route d'Auxerre",
        "addressLocality": "Saint André les vergers",
        "postalCode": "10120",
        "addressCountry": "FR"
      },
      "telephone": "0310940413",
      "email": "cedric@le-hir.name",
      "priceRange": "$",
      "image": image
    },
  ];

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          '@context': "https://schema.org",
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': url,
                name: title,
                image:image,
              },
            },
          ],
        },
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": url
          },
          "headline": description,
          "image": image,
          "datePublished": datePublished,
          "dateModified": dateModified,
          "author": {
            "@type": "Person",
            "name": SeoConfig.author
          },
           "publisher": {
            "@type": "Organization",
            "name": "Cédric LE HIR EI",
            "logo": {
              "@type": "ImageObject",
              "url": SeoConfig.image
            }
          },
          "description": description
        },
      ]
    : schemaOrgJSONLD;
};

const SEO = ({ title, description, postImage, isBlogPost, slug, dateModified, datePublished }) => {

  title = `${title} |  ${SeoConfig.title}`;
  description = description || SeoConfig.description; //|| postData.excerpt
  const image = postImage || SeoConfig.image; //`${SeoConfig.siteUrl}${postImage}` ||
  const url = slug
    ? `${SeoConfig.siteUrl}/${slug}`
    : SeoConfig.siteUrl;
  datePublished = isBlogPost ? datePublished : false;
  dateModified = isBlogPost ? dateModified : false;

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url,
    title,
    image,
    description,
    datePublished,
    dateModified,
  });

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@cedlhr" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  postImage: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  dateModified: PropTypes.string,
  datePublished: PropTypes.string,
  slug: PropTypes.string,
};

SEO.defaultProps = {
  isBlogPost: false,
  postImage: null,
  lang: `fr`,
  meta: [],
  keywords: ['psychologie','psychologue','troyes','saint andré les vergers','aube','psychiatre','psychiatrie','thérapie','enfant','adulte','adolescent','bébé','couple','famille','thérapie','psychothérapie','psychologie','psychopraticien']
};

export default SEO;
